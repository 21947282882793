<template>
  <div class="global-searchbar">
    <div
      class="content-header-global"
      :class="'content-header-' + $route.name.trim()"
    >
      <div class="content-text-title">
        <p class="text-title">{{ sTextTitle }}</p>
      </div>

      <!-- #region FILTRO DE RANGO DE FECHAS (O.C.) -->
      <div
        v-if="bViewFilterRangeDate && !isMobile"
        class="content-filter-date-range"
      >
        <v-menu
          ref="menu"
          v-model="menu"
          attach
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="sDates"
              outlined
              chips
              clearable
              class="global-text-field global-text-field-date"
              label="Seleccionar rango de fechas"
              placeholder="Seleccionar rango de fechas..."
              color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)"
              v-bind="attrs"
              v-on="on"
              readonly
            >
              <template slot="append">
                <v-icon @click="menu = true">mdi-calendar-blank</v-icon>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            :max="sMaxDate"
            range
            no-title
            locale="es"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancelar </v-btn>
            <v-btn
              :disabled="!bAddDates"
              text
              color="primary"
              @click="$refs.menu.save(dates), save(dates)"
            >
              <span :class="bAddDates ? 'color-yellow-global' : ''"
                >Guardar</span
              >
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
      <!-- #endregion FILTRO DE RANGO DE FECHAS (O.C.) -->

      <div v-show="setShowBtnAdd()" class="content-btns">
        <div class="content-btn-add">
          <v-btn
            v-show="bAdminPermissions"
            @click="openDialogOrViewAdd()"
            class="btn-add-global global-btn-primary"
            :class="isMobile ? 'btn-add-global-mobile' : ''"
          >
            <span class="text-btn-add-mobile" v-if="isMobile">+</span>
            <span v-else>{{ sTextBtnAdd }}</span>
          </v-btn>
        </div>
      </div>

      <div class="content-status-oc" v-if="false">
        <div
          class="content-status"
          :class="item.class"
          v-for="(item, i) in aContentStatus"
          :key="i"
        >
          <v-btn
            class="icon-status"
            icon
            :style="{ 'background-color': item.color }"
          >
            <v-icon size="20"> {{ item.icon }} </v-icon>
          </v-btn>
          <br />
          <p class="content-number-status">{{ item.totalOC }}</p>
          <p class="content-text-status">{{ item.text }}</p>
        </div>
      </div>

      <!-- #region BTN REFRESH TABLE -->
      <div v-if="bShowBtnRefresh" class="content-btns">
        <div class="content-btn-add">
          <v-btn
            v-show="bAdminPermissions"
            @click="setRefreshTable()"
            :loading="bLoadingBtnRefresh"
            class="btn-add-global global-btn-primary btn-refresh"
            :class="isMobile ? 'btn-add-global-mobile' : ''"
          >
            <span class="text-btn-add-mobile" v-if="isMobile">
              <span class="material-icons"> refresh </span>
            </span>
            <span class="text-btn-refresh" v-else
              ><span class="material-icons"> refresh </span
              >{{ sTextBtnAdd }}</span
            >
          </v-btn>
        </div>
      </div>
      <!-- #endregion BTN REFRESH TABLE -->
    </div>
    <!-- #region FILTRO DE RANGO DE FECHAS (O.C.) -->
    <div
      v-if="bViewFilterRangeDate && isMobile"
      class="content-filter-date-range"
    >
      <v-menu
        ref="menu"
        v-model="menu"
        attach
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="sDates"
            outlined
            chips
            clearable
            class="global-text-field global-text-field-date"
            label="Seleccionar rango de fechas"
            placeholder="Seleccionar rango de fechas..."
            color="var(--primary-color-border-input)"
            background-color="var(--primary-color-menu)"
            v-bind="attrs"
            v-on="on"
            readonly
          >
            <template slot="append">
              <v-icon @click="menu = true">mdi-calendar-blank</v-icon>
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          v-model="dates"
          :max="sMaxDate"
          range
          no-title
          locale="es"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancelar </v-btn>
          <v-btn
            :disabled="!bAddDates"
            text
            color="blue"
            @click="$refs.menu.save(dates), save(dates)"
          >
            <span :class="bAddDates ? 'color-yellow-global' : ''">Guardar</span>
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <!-- #endregion FILTRO DE RANGO DE FECHAS (O.C.) -->
  </div>
</template>
<script>
export default {
  props: {
    bDialogAdd: Boolean,
    sTextTitle: String,
    sTextBtnAdd: String,
    bAdminPermissions: Boolean,
    setDialog: String,
    toVieworModal: { type: Boolean, default: false },
  },
  data() {
    return {
      isMobile: false,
      aShowBtnAdd: [
        "Provider",
        "RawMaterial",
        "Administrators",
        "Account",
        "Customer",
        "OPPending",
      ],
      aShowDashboardOC: ["OPGeneral"],
      aShowBtnRefresh: ["Audit"],
      aContentStatus: [
        {
          id: 1,
          text: "En proceso",
          icon: "mdi-arrow-right-circle-outline",
          class: "mr-2",
          color: "var(--primary-color-background-icon-status-blue)",
          totalOC: 24,
        },
        {
          id: 2,
          text: "Finalizados",
          icon: "mdi-check-circle-outline",
          class: "mr-2",
          color: "var(--primary-color-background-icon-status-green)",
          totalOC: 122,
        },
        {
          id: 3,
          text: "Canceladas",
          icon: "mdi-close-circle-outline",
          class: "",
          color: "var(--primary-color-background-icon-status-red)",
          totalOC: 2,
        },
      ],
      dates: [],
      sDates: "",
      menu: false,
      sMaxDate: new Date().toISOString().slice(0, 10),
      aViewFilterRangeDate: [
        "Dashboard",
        "OPGeneral",
        "OPPending",
        "OPPayable",
      ],
      
      bLoadingBtnRefresh: false,
      bAddDates: false,
    };
  },
  beforeMount() {
    if (this.bViewFilterRangeDate) {
      this.fillRangeDateOP();
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  mounted() {
    if (this.bViewFilterRangeDate) {
      if (this.aRangeDatesGlobal.length > 0) {
        this.$refs.menu.save(this.aRangeDatesGlobal);
      } else {
        this.$refs.menu.save([]);
        this.dates = [];
        this.sDates = "";
      }
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth > 599) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
    openDialogOrViewAdd() {
      if (this.toVieworModal) {
        this.$router
          .push({
            name: this.setDialog,
            params: {},
          })
          .catch(() => {});
      } else {
        this.$emit("setDialogAdd");
      }
    },
    setRefreshTable() {
      this.bLoadingBtnRefresh = true;
      this.$store.commit("refresher", true);
    },
    setShowBtnAdd() {
      return this.aShowBtnAdd.filter((e) => e === this.$route.name).length > 0;
    },
    setShowDashboardOC() {
      return (
        this.aShowDashboardOC.filter((e) => e === this.$route.name).length > 0
      );
    },
    save(date) {
      this.$refs.menu.save(date);
      if (this.dates.length > 1) {
        let fecha_inicial = this.dates[0];
        let fecha_final = this.dates[1];
        if (Date.parse(fecha_inicial) > Date.parse(fecha_final)) {
          let lastDate = this.dates[1];
          this.dates = [];
          this.dates[0] = lastDate;
        } else {
          this.$store.commit("setRangeDateGlobal", this.dates);
          this.$emit("setRangeDate", {
            tStart: fecha_inicial,
            tEnd: fecha_final,
          });
          this.sDates = fecha_inicial + "-" + fecha_final;
        }
      }
    },
    fillRangeDateOP() {
      if (this.aRangeDatesGlobal.length > 0) {
        this.dates = this.aRangeDatesGlobal;
        let fecha_inicial = this.dates[0];
        let fecha_final = this.dates[1];
        this.$emit("setRangeDate", {
          tStart: fecha_inicial,
          tEnd: fecha_final,
        });
        this.sDates = fecha_inicial + "-" + fecha_final;
      } else {
        this.$emit("setRangeDate", {
          tStart: null,
          tEnd: null,
        });
        this.sDates = "";
      }
    },
  },
  computed: {
    sNameRouteGlobal() {
      return this.$store.state.sNameRouteGlobal;
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
    bViewFilterRangeDate() {
      return (
        this.aViewFilterRangeDate.filter((e) => e === this.$route.name).length >
        0
      );
    },
    bShowBtnRefresh() {
      return (
        this.aShowBtnRefresh.filter((e) => e === this.$route.name).length > 0
      );
    },
    bDashboard() {
      return this.$route.name === "Dashboard";
    },
    aRangeDatesGlobal() {
      return this.$store.state.aRangeDatesGlobal;
    },
  },
  watch: {
    // $route: {
    //   immediate: true,
    //   deep: true,
    //   handler(route) {
    //     if (
    //       route.name === "OPGeneral" ||
    //       route.name === "OPPending" ||
    //       route.name === "OPPayable"
    //     ) {
    //       if (this.sNameRouteGlobal !== route.name) {
    //         this.$store.commit("setRangeDateGlobal", []);
    //       }
    //       this.$store.commit("setNameRouteGlobal", route.name);
    //     } else {
    //       this.$store.commit("setRangeDateGlobal", []);
    //     }
    //   },
    // },
    dates() {
      if (this.dates !== null) {
        if (this.dates.length > 1) {
          this.bAddDates = true;
          let fecha_inicial = this.dates[0];
          let fecha_final = this.dates[1];
          if (Date.parse(fecha_inicial) > Date.parse(fecha_final)) {
            let lastDate = this.dates[1];
            this.dates = [];
            this.dates[0] = lastDate;
          }
        } else {
          this.bAddDates = false;
        }
      } else {
        this.bAddDates = false;
        this.$store.commit("setRangeDateGlobal", []);
        this.$emit("setRangeDate", {
          tStart: null,
          tEnd: null,
        });
      }
    },
    sDates() {
      if (this.sDates == null) {
        this.dates = [];
        this.$refs.menu.save([]);
        this.$store.commit("setRangeDateGlobal", this.dates);
        this.$emit("setRangeDate", {
          tStart: null,
          tEnd: null,
        });
      }
    },
    refreshTable() {
      this.bLoadingBtnRefresh = this.refreshTable;
    },
  },
};
</script>
<style scoped>
.content-header-global {
  display: flex;
  align-content: center;
  width: 100%;
}

.content-add-provider {
  display: flex;
  justify-content: end;
  align-content: center;
  width: 100%;
}

.content-add-operation {
  display: flex;
  justify-content: end;
  align-content: center;
  width: 100%;
}

.content-btn-add {
  margin-left: 15px;
}

.content-text-title {
  width: 100%;
}

.text-title {
  color: var(--primary-color-text);
  font-family: "pop-SemiBold";
  font-size: 30px;
  margin-bottom: 0px;
  margin-left: -2px;
}

.text-btn-add-mobile {
  font-size: 22px;
  display: flex;
  align-items: center;
}

/* .btn-refresh {
  color: black !important;
} */
.text-btn-refresh {
  display: flex;
  align-content: center;
  align-items: center;
}

/*#region ESTILO DASHBOARD OC */

.content-filter-date-range {
  width: 610px;
}

.content-status-oc {
  display: flex;
  align-items: center;
}

.content-status {
  display: flex;
  height: 50px;
  width: 200px;
  background-color: var(--primary-color-text-background-status);
  border-radius: 20px;
  padding: 10px;
  border: 1px solid var(--primary-color-border-text-field);
  color: var(--primary-color-text);
  text-align: center;
  margin-bottom: 18px;
}

.icon-status {
  margin-bottom: 5px;
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.content-text-status {
  font-family: "pop-Regular";
  font-size: 11px;
  margin-bottom: 0px;
  place-self: center;
}

.content-number-status {
  font-family: "pop-semiBold";
  font-size: 22px;
  margin-bottom: 0px;
  place-self: center;
  margin-right: 15px;
}

.content-status:hover {
  transform: scale(1.05);
  transition: 0.2s;
  box-shadow: 21px 18px 50px -19px var(--primary-color-btn-primary);
}

/*#endregion ESTILO DASHBOARD OC */

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-header-global {
    display: flex;
  }

  .content-header-OPGeneral {
    display: block !important;
  }

  .content-header-OPPending {
    display: flex !important;
  }

  .content-header-OPPayable {
    display: block !important;
  }

  .content-add-provider {
    display: block;
  }

  .content-add-operation {
    display: block;
  }

  .btn-add-global-mobile {
    width: 50px !important;
    max-width: 50px !important;
    min-width: 50px !important;
  }

  .content-btn-add {
    margin-left: 0px;
  }

  .text-title {
    color: var(--primary-color-text);
    font-family: "pop-SemiBold";
    font-size: 25px;
    margin-bottom: 0px;
    margin-left: -2px;
  }

  /* #region ESTILO DASHBOARD OC */

  .content-filter-date-range {
    margin-top: 20px;
    width: 100%;
  }

  .content-status-oc {
    display: flex;
    align-items: center;
    width: 100%;
    place-content: center;
  }

  .content-status {
    height: 105px;
    width: 106px;
    background-color: var(--primary-color-text-background-status);
    border-radius: 20px;
    padding: 10px;
    border: 1px solid var(--primary-color-border-text-field);
    color: var(--primary-color-text);
    text-align: center;
    display: block;
    margin-bottom: 18px;
  }

  .icon-status {
    margin-bottom: 5px;
    height: 25px;
    width: 25px;
    margin-right: 0px !important;
  }

  .content-text-status {
    font-family: "pop-Regular";
    font-size: 11px;
    margin-bottom: 0px;
    margin-right: 0px !important;
  }

  .content-number-status {
    font-family: "pop-semiBold";
    font-size: 22px;
    margin-bottom: 0px;
    margin-right: 0px !important;
  }

  /* #endregion ESTILO DASHBOARD OC */
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  /* #region ESTILO DASHBOARD OC */
  .content-status {
    height: 105px;
    width: 106px;
    background-color: var(--primary-color-text-background-status);
    border-radius: 20px;
    padding: 10px;
    border: 1px solid var(--primary-color-border-text-field);
    color: var(--primary-color-text);
    text-align: center;
    display: block;
    margin-bottom: 18px;
  }

  .icon-status {
    margin-bottom: 5px;
    height: 25px;
    width: 25px;
    margin-right: 0px !important;
  }

  .content-text-status {
    font-family: "pop-Regular";
    font-size: 11px;
    margin-bottom: 0px;
    margin-right: 0px !important;
  }

  .content-number-status {
    font-family: "pop-semiBold";
    font-size: 22px;
    margin-bottom: 0px;
    margin-right: 0px !important;
  }

  /* #endregion ESTILO DASHBOARD OC */
}

/* MD */
@media (min-width: 960px) and (max-width: 1228px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }

  .content-status-oc {
    display: flex;
    align-items: center;
  }

  .content-status {
    height: 105px;
    width: 106px;
    background-color: var(--primary-color-text-background-status);
    border-radius: 20px;
    padding: 10px;
    border: 1px solid var(--primary-color-border-text-field);
    color: var(--primary-color-text);
    text-align: center;
    display: block;
    margin-bottom: 18px;
  }

  .icon-status {
    margin-bottom: 5px;
    height: 25px;
    width: 25px;
    margin-right: 0px !important;
  }

  .content-text-status {
    font-family: "pop-Regular";
    font-size: 11px;
    margin-bottom: 0px;
    margin-right: 0px !important;
  }

  .content-number-status {
    font-family: "pop-semiBold";
    font-size: 22px;
    margin-bottom: 0px;
    margin-right: 0px !important;
  }
}

@media (min-width: 1228px) and (max-width: 1263px) {
  .content-status-oc {
    display: flex;
    align-items: center;
  }

  .content-status {
    height: 105px;
    width: 106px;
    background-color: var(--primary-color-text-background-status);
    border-radius: 20px;
    padding: 10px;
    border: 1px solid var(--primary-color-border-text-field);
    color: var(--primary-color-text);
    text-align: center;
    display: block;
    margin-bottom: 18px;
  }

  .icon-status {
    margin-bottom: 5px;
    height: 25px;
    width: 25px;
    margin-right: 0px !important;
  }

  .content-text-status {
    font-family: "pop-Regular";
    font-size: 11px;
    margin-bottom: 0px;
    margin-right: 0px !important;
  }

  .content-number-status {
    font-family: "pop-semiBold";
    font-size: 22px;
    margin-bottom: 0px;
    margin-right: 0px !important;
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
